import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/CAT/layout'
import BannerLanding from '../../components/CAT/BannerLanding'

import pic08 from '../../assets/images/lor2.jpg'
import pic09 from '../../assets/imgs/landing/p1.jpg'

import pic10 from '../../assets/imgs/landing/p2.jpg'
import pic11 from '../../assets/imgs/landing/q1.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <p style ={{ textAlign: "justify" }}>UNIDANS és un projecte complex que involucra a socis de tres països europeus i es centra
principalment en l’intercanvi transnacional d’experiències relacionades amb la dansa
adaptada/unificada. El projecte contempla la implicació d’uns 100 entrenadors de dansa i
voluntaris/país, els quals seran formats en l’àmbit de la dansa adaptada/unificada, i la d’unes
20 entitats no lucratives/país que treballin en l’àmbit de la inclusió social de persones amb
discapacitat i/o la dansa/arts escèniques. Tots ells tindran l’oportunitat de formar part dels
esdeveniments locals de networking UNIDANS.</p>

                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        
                        <div className="inner">
                            <header className="major">
                                <h3>Objectiu del projecte</h3>
                            </header>
                            <p>L’objectiu del projecte és el de promoure l’educació
en/a través de l’esport, parant especial atenció al desenvolupament d’habilitats per part dels
entrenadors que treballen en l’àmbit de l’esport adaptat/unificat en general, i de la dansa
adaptada/unificada en particular.</p>
                            <ul className="actions">
                                <li><Link to="/CAT/generic" className="button">Més informació</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                 <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Objectius</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/CAT/objectives" className="button">Més informació</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/CAT/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Activitats</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/CAT/activities" className="button">Més informació</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
                <section>
                    <Link to="/CAT/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>A qui ens dirigim </h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/CAT/address" className="button">Més informació</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
            </section>
        </div>

    </Layout>
)

export default Landing